"use client";
import React, { useState, useEffect } from "react";
import { CloseButton, Dropdown } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Link from "next/link";
import LoginModal from "../LoginModal/LoginModal";
import AreaConverter from "../AreaConverter/AreaConverter";
import { useSelector } from "react-redux";
import { logoutSuccess, userSignUpData } from "@/store/reducer/authSlice";
import { logoutLoaded } from "@/store/reducer/authSlice";
import { CiGlobe } from "react-icons/ci";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-hot-toast";
import { settingsData } from "@/store/reducer/settingsSlice";
import { languageLoaded, setLanguage } from "@/store/reducer/languageSlice";
import { placeholderImage, translate } from "@/utils";
import { store } from "@/store/store";
import Swal from "sweetalert2";
import { useRouter } from "next/router";
import Image from "next/image";
import FirebaseData from "@/utils/Firebase";
import { AiOutlineInstagram } from "react-icons/ai";
import { CiFacebook } from "react-icons/ci";
import { ImPinterest2 } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";
import baityLogo from "@/assets/Logo_Color.png";
import dummyimg from "@/assets/Images/user_profile.png";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { usePathname } from "next/navigation";

const Nav = ({ stikyNav }) => {
  const router = useRouter();
  const language = store.getState().Language.languages;
  const { signOut } = FirebaseData();

  const currentLanguage = useSelector(
    (state) => state.Language.selectedLanguage,
  );

  const signupData = useSelector(userSignUpData);

  const settingData = useSelector(settingsData);
  const webdata = settingData && settingData;

  const isSubscription = settingData?.subscription;

  const systemDefaultLanguageCode = settingData?.default_language;
  const [showModal, setShowModal] = useState(false);
  const [areaconverterModal, setAreaConverterModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [defaultlang, setDefaultlang] = useState(language.name);
  const [show, setShow] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [scroll, setScroll] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userProfileData = signupData?.data?.data?.profile;
  const userData = signupData?.data?.data;

  const handelAddProperty = () => {
    signupData.data !== null
      ? router.push("/user/properties")
      : router.push("/login");
  };

  const handelRentRedirect = () => {
    router.push("/");
  };

  const handelRequistProperty = () => {
    signupData.data !== null
      ? router.push("/user/properties/requist-listing")
      : router.push("/login");
  };

  useEffect(() => {
    if (language && language.rtl === 1) {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }
  }, [language]);

  useEffect(() => {
    const header = document.querySelector(".header");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!language || Object.keys(language).length === 0) {
      languageLoaded(
        systemDefaultLanguageCode,
        "1",
        (response) => {
          const currentLang = response && response.data.name;

          // Dispatch the setLanguage action to update the selected language in Redux
          store.dispatch(setLanguage(currentLang));
          setSelectedLanguage(currentLang);
          setDefaultlang(currentLang);
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }, []);

  const handleLanguageChange = (event) => {
    const isChecked = event.target.checked;
    const newLanguage = isChecked ? "ar" : "en";

    store.dispatch(setLanguage(newLanguage));

    languageLoaded(
      newLanguage,
      isChecked ? "2" : "1",
      (response) => {
        const currentLang = response && response.data.name;
        // Dispatch the setLanguage action to update the selected language in Redux
        store.dispatch(setLanguage(currentLang));
        setSelectedLanguage(currentLang);
        setDefaultlang(currentLang);
      },
      (error) => {
        console.log(error);
      },
    );
  };
  useEffect(() => {}, [selectedLanguage, language, defaultlang]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseAcModal = () => {
    setAreaConverterModal(false);
  };

  const handleShowDashboard = () => {
    router.push("/user/profile");
  };

  const handleLogout = () => {
    handleClose();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "Swal-confirm-buttons",
        cancelButton: "Swal-cancel-buttons",
      },
      confirmButtonText: "Yes! Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        window.recaptchaVerifier = null;

        try {
          logoutLoaded();
        } catch (error) {
          console.error(error);
        }

        logoutSuccess();
        signOut();

        toast.success(translate("logoutSuccess"));
      } else {
        toast.error(translate("logoutcancel"));
      }
    });
  };

  const CheckActiveUserAccount = () => {
    if (settingData?.is_active === false) {
      Swal.fire({
        title: "Opps!",
        text: "Your account has been deactivated by the admin. Please contact them.",
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: false,
        customClass: {
          confirmButton: "Swal-confirm-buttons",
          cancelButton: "Swal-cancel-buttons",
        },
        confirmButtonText: "Logout",
      }).then((result) => {
        if (result.isConfirmed) {
          logoutSuccess();
          signOut();
          router.push("/contact-us");
        }
      });
    }
  };
  useEffect(() => {
    CheckActiveUserAccount();
  }, [settingData?.is_active]);

  // current path
  const pth = usePathname();
  return (
    <>
      <header className={`${stikyNav ? "sticy" : "border-b"} px-8 md:px-12`}>
        <nav className={`flex header navbar-expand-lg navbar-light container`}>
          <div className="w-full flex justify-between md:grid-cols-3">
            {/* Logo on the left */}
            <div className="flex items-center">
              <Link href="/" aria-label="Baity Brand Logo">
                <Image
                  loading="lazy"
                  src={baityLogo}
                  alt="Baity Brand Logo"
                  width={350}
                  height={157}
                  className="nav_logo"
                  onError={placeholderImage}
                />
              </Link>
            </div>

            {/* Hamburger menu on the right for mobile */}
            <div className="flex justify-end items-center grow lg:hidden">
              <span onClick={handleShow} id="hamburg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#34484f"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </span>
            </div>

            {/* Full navigation menu for desktop */}
            <div className="flex md:hidden lg:flex">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item ">
                    <button
                      onClick={handelRentRedirect}
                      className={`nav-link text-base hover:text-[#0075FF] ${pth === `/` && `text-[#0075FF] !font-bold`}`}
                    >
                      {translate("home")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={handelAddProperty}
                      className={`nav-link hover:text-[#0075FF] ${pth.includes(`post-listing`) && `text-[#0075FF] !font-bold`}`}
                    >
                      {translate("addListing")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={handelRequistProperty}
                      className={`nav-link hover:text-[#0075FF] ${pth.includes(`requist-listing`) && `text-[#0075FF] !font-bold`}`}
                      href="/requist-listing"
                    >
                      {translate("propertyRequest")}
                    </button>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link hover:text-[#0075FF] ${pth.includes(`properties/all-properties`) && `text-[#0075FF] !font-bold`}`}
                      href="/properties/all-properties"
                    >
                      {translate("AllProperties")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link hover:text-[#0075FF] ${pth.includes(`find-agents`) && `text-[#0075FF] !font-bold`}`}
                      href="/find-agents"
                    >
                      {translate("findAgent")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Full navigation menu items on the right for desktop */}
            <div className="flex md:hidden lg:flex">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto gap-3">
                  <li className="nav-lang">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                          <CiGlobe className="font-extrabold text-lg" />
                          EN
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-16 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              AR
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </li>

                  <li className="nav-item">
                    {
                      // Check if signupData.data is null
                      signupData?.data === null ? (
                        <div className="d-flex gap-3">
                          <Link
                            href="/login"
                            className="button button-outline rounded-xl"
                          >
                            {translate("SignIn")}
                          </Link>
                          <Link
                            href="/register"
                            className="button button-solid rounded-xl"
                          >
                            {translate("SignUp")}
                          </Link>
                        </div>
                      ) : // Check if mobile and firebase_id are present
                      signupData ? (
                        <div className="flex items-center justify-center gap-x-3">
                          <Link
                            href="#"
                            className="p-2.5 border rounded-full hover:bg-[#0075FF] hover:border-[#0075FF] ease-in-out duration-200 group"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                            >
                              <g
                                id="Group_3"
                                data-name="Group 3"
                                transform="translate(-714 -415)"
                              >
                                <rect
                                  id="Rectangle_2"
                                  data-name="Rectangle 2"
                                  width="21"
                                  height="21"
                                  transform="translate(714 415)"
                                  fill="none"
                                />
                                <path
                                  id="notifecationIcon"
                                  d="M19.16,17h1.907v2H2V17H3.907V10a7.82,7.82,0,0,1,7.627-8,7.82,7.82,0,0,1,7.627,8Zm-1.907,0V10a5.865,5.865,0,0,0-5.72-6,5.865,5.865,0,0,0-5.72,6v7Zm-8.58,4h5.72v2H8.673Z"
                                  transform="translate(712.933 413)"
                                  className="fill-[#0075FF] group-hover:fill-white ease-in-out duration-200"
                                />
                              </g>
                            </svg>
                            <span className="sr-only">Chat link redirect</span>
                          </Link>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic01">
                              <div className="border rounded-full py-1.5 px-2.5 flex gap-x-2 items-center justify-between">
                                <Image
                                  src={userProfileData || dummyimg.src}
                                  alt={"User Profile Picture"}
                                  width={32}
                                  height={32}
                                  className="object-cover rounded-full w-8 h-8"
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#333"
                                  className="size-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              className="!rounded-lg"
                              id="language"
                            >
                              <div className="px-2 !flex flex-col !gap-y-2">
                                <Dropdown.Item
                                  onClick={handleShowDashboard}
                                  className="flex py-2 items-center gap-x-2 group !text-[#5A727B] hover:!bg-[#F6F6F6] rounded-sm hover:m-0 focus:m-0 ease-in-out duration-200"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                  >
                                    <g
                                      id="Group_6"
                                      data-name="Group 6"
                                      transform="translate(-728 -443)"
                                    >
                                      <rect
                                        id="Rectangle_4"
                                        data-name="Rectangle 4"
                                        width="21"
                                        height="21"
                                        transform="translate(728 443)"
                                        fill="none"
                                      />
                                      <path
                                        id="Vector"
                                        d="M.5.833A.833.833,0,0,1,1.333,0h5a.833.833,0,0,1,.833.833v5a.833.833,0,0,1-.833.833h-5A.833.833,0,0,1,.5,5.833Zm0,8.333a.833.833,0,0,1,.833-.833h5a.833.833,0,0,1,.833.833v5A.833.833,0,0,1,6.333,15h-5A.833.833,0,0,1,.5,14.167ZM8.833.833A.833.833,0,0,1,9.667,0h5A.833.833,0,0,1,15.5.833v5a.833.833,0,0,1-.833.833h-5a.833.833,0,0,1-.833-.833Zm0,8.333a.833.833,0,0,1,.833-.833h5a.833.833,0,0,1,.833.833v5a.833.833,0,0,1-.833.833h-5a.833.833,0,0,1-.833-.833Zm1.667-7.5V5h3.333V1.667ZM10.5,10v3.333h3.333V10ZM2.167,1.667V5H5.5V1.667Zm0,8.333v3.333H5.5V10Z"
                                        transform="translate(730.5 446)"
                                        className="fill-[#5A727B]"
                                      />
                                    </g>
                                  </svg>
                                  {translate("dashboard")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={handleLogout}
                                  className="flex py-2 items-center gap-x-2 group hover:!bg-[#F6F6F6] rounded-sm hover:m-0 focus:m-0 ease-in-out duration-200"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                  >
                                    <g
                                      id="Group_4"
                                      data-name="Group 4"
                                      transform="translate(-770 -443)"
                                    >
                                      <rect
                                        id="Rectangle_6"
                                        data-name="Rectangle 6"
                                        width="21"
                                        height="21"
                                        transform="translate(770 443)"
                                        fill="none"
                                      />
                                      <path
                                        id="Vector_2_"
                                        data-name="Vector (2)"
                                        d="M3.333,13.5H5v1.667H15V1.834H5V3.5H3.333V1A.833.833,0,0,1,4.166.167H15.833A.833.833,0,0,1,16.666,1V16a.833.833,0,0,1-.833.833H4.166A.833.833,0,0,1,3.333,16ZM5,7.667h5.833V9.334H5v2.5L.833,8.5,5,5.167Z"
                                        transform="translate(772.167 444.833)"
                                        fill="red"
                                      />
                                    </g>
                                  </svg>
                                  <span className="text-red-500">
                                    {translate("logout")}
                                  </span>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : null
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          scroll={false}
          backdrop={true}
          style={{
            width: "90%",
            maxWidth: "400px",
          }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <span className="title-name">{translate("menu")}</span>
            </Offcanvas.Title>
            <Offcanvas.Title>
              <CloseButton onClick={handleClose} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="d-flex flex-column gap-2 justify-content-between h-100">
              <ul className="navbar-nav" id="mobile-ul">
                <li className="nav-item">
                  <Link
                    className={`nav-link hover:text-[#0075FF] ${pth === `/` && `text-[#0075FF] !font-bold`}`}
                    href="/"
                    onClick={handleClose}
                  >
                    {translate("home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link hover:text-[#0075FF] ${pth.includes(`user/properties/post-listing`) && `text-[#0075FF] !font-bold`}`}
                    href="/user/properties/post-listing"
                    onClick={handleClose}
                  >
                    {translate("addProperty")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link hover:text-[#0075FF] ${pth.includes(`user/properties/requist-listing`) && `text-[#0075FF] !font-bold`}`}
                    href="/user/properties/requist-listing"
                    onClick={handleClose}
                  >
                    {translate("propertyRequest")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link hover:text-[#0075FF] ${pth.includes(`find-agents`) && `text-[#0075FF] !font-bold`}`}
                    href="/find-agents"
                    onClick={handleClose}
                  >
                    {translate("findAgent")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={handleClose}
                    href="/properties/all-properties"
                    className={`nav-link hover:text-[#0075FF] ${pth.includes(`properties/all-properties`) && `text-[#0075FF] !font-bold`}`}
                  >
                    {translate("AllProperties")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link hover:text-[#0075FF] ${pth.includes(`properties/listing-requests`) && `text-[#0075FF] !font-bold`}`}
                    href="/properties/listing-requests"
                    onClick={handleClose}
                  >
                    {translate("listingRequest")}
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="nav-item nav-lang  justify-content-center mt-3">
                  <span>EN</span>
                  <div className="language-switch">
                    <input
                      type="checkbox"
                      name=""
                      id="language"
                      onChange={handleLanguageChange}
                      checked={currentLanguage === "ar"}
                    />
                    <label className="" htmlFor="language"></label>
                  </div>
                  <span>AR</span>
                </li>
              </ul>

              <div className="">
                {webdata?.facebook_id ||
                webdata?.instagram_id ||
                webdata?.pintrest_id ||
                webdata?.twitter_id ? (
                  <div
                    id="follow_us"
                    className="d-flex justify-content-center text-muted"
                  >
                    {webdata?.facebook_id ? (
                      <Link
                        href={webdata?.facebook_id}
                        target="_blank"
                        aria-label="facebook page"
                        className="text-muted"
                      >
                        <CiFacebook size={28} />
                        <span className="hidden-text-seo">facebook page</span>
                      </Link>
                    ) : null}
                    {webdata?.instagram_id ? (
                      <Link
                        href={webdata?.instagram_id}
                        target="_blank"
                        aria-label="instagram page"
                        className="text-muted"
                      >
                        <AiOutlineInstagram size={28} />
                        <span className="hidden-text-seo">
                          instagram profile
                        </span>
                      </Link>
                    ) : null}
                    {webdata?.pintrest_id ? (
                      <Link
                        href={webdata?.pintrest_id}
                        target="_blank"
                        aria-label="pintrest page"
                        className="text-muted"
                      >
                        <ImPinterest2 size={25} />
                        <span className="hidden-text-seo">pintrest page</span>
                      </Link>
                    ) : null}
                    {webdata?.twitter_id ? (
                      <Link
                        href={webdata?.twitter_id}
                        target="_blank"
                        aria-label="twitter page"
                        className="text-muted"
                      >
                        <FaXTwitter size={25} />
                        <span className="hidden-text-seo">twitter page</span>
                      </Link>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <LoginModal isOpen={showModal} onClose={handleCloseModal} />

      <AreaConverter isOpen={areaconverterModal} onClose={handleCloseAcModal} />
    </>
  );
};

export default Nav;
